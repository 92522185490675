<template>
  <div>
    <div class="flex flex-grow justify-between items-center">
      <InputField
        placeholder="Name, job title or team"
        label="Filter by"
        :onChange="setKeyword"
        leftIcon
        class="mb-2 w-inputField"
        data-testid="filter-field"
      />
      <Button
        text="Assign Job Role"
        @click.native="assignJobRoles({ all: true, index: null })"
        :disabled="isAnySelected"
        data-testid="assign-role-button"
      />
    </div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">
      Showing {{ mappedList.length }} out of {{ totalCount }} participants ({{ selectedItems.size }}
      Selected)
    </p>
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 px-2 rounded">
        <button class="mr-2" @click="toggleAll">
          <Checkbox :active="selectedAllActive" data-testid="select-all-checkbox" />
        </button>
        <div
          class="flex flex-row items-center focus:outline-none w-tableIndex mr-2"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
        </div>
        <div class="flex flex-grow w-1/5">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('name', 0)"
            data-testid="header-1"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">NAME</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/5">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('jobTitle', 1)"
            data-testid="header-2"
          >
            <p class="text-sm-2 text-grey-light mr-0.4">JOB TITLE</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
        <div class="flex flex-grow w-1/5">
          <div class="flex flex-row items-center focus:outline-none">
            <p class="text-sm-2 text-grey-light mr-0.4">TEAM</p>
            </div>
        </div>
        <div class="flex flex-grow w-1/4">
          <button
            class="flex flex-row items-center focus:outline-none"
            @click="sort('jobRole', 3)"
            data-testid="header-3"
          >
            <p class="text-sm-2 text-grey-light mr-0.4  -ml-2">JOB ROLE</p>
            <img
              src="../../../../assets/img/delta-table/caret.svg"
              class="transition-all"
              :class="rotationMatrix[3] !== 1 ? 'transform rotate-180' : null"
            />
          </button>
        </div>
      </div>
      <div>
        <VirtualList
          style="max-height: 364px; min-height: 156px; overflow-y: auto"
          :data-key="'id'"
          :data-sources="mappedList"
          :data-component="itemComponent"
          :extra-props="{
            toggle: toggleItem,
            total: mappedList.length,
            assignJobRoles,
          }"
          data-testid="job-roles-table"
          @tobottom="triggerFetch"
        />
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import { mapState, mapActions } from "vuex";
import Checkbox from "../../../../components/Checkbox/";
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import InputField from "../../../../components/InputField/InputField.vue";
import Button from "../../../../components/Button/Button.vue";
import _forEach from "lodash/forEach";

export default {
  name: "TeamTable",
  components: { Checkbox, VirtualList, InputField, Button },
  data: () => ({
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1, 1],
    mappedList: [],
    selectedItems: new Set([]),
    isSelectAllActive: false,
    sorting: null,
    pageNr: 1,
    pageSize: 20,
    assign: { all: true, index: null },
  }),
  props: { handler: { type: Function, default: () => {} } },
  computed: {
    ...mapState({
      id: (state) => state.assessmentSetup.cloneId,
      participants: (state) => state.assessmentSetup.participants,
      selectedJobRole: (state) => state.assessmentSetup.selectedJobRole,
      jobRoles: (state) => state.assessmentSetup.jobRolesForSurvey,
      totalCount: (state) => state.assessmentSetup.participantsCount,
    }),
    selectedAllActive() {
      return !!(this.mappedList.length && this.isSelectAllActive);
    },
    isAnySelected() {
      let count = 0;
      _forEach(this.mappedList, (el) => {
        if (el.isSelected) {
          count += 1;
        }
      });
      return count === 0;
    },
  },
  async mounted() {
    await this.updateList(true);
    this.handler(this.mappedList, true);
  },
  watch: {
    selectedJobRole(current) {
      if (this.assign.all) {
        this.mappedList = this.mappedList.map((item) => {
          if (item.isSelected) {
            item.jobRoleName = current.name;
            item.jobRoleId = current.id;
            item.jobRoleDescription = current.description;
            item.isSelected = false;
          }
          return item;
        });
        this.selectedItems = new Set([])
      } else {
        this.mappedList[this.assign.index].jobRoleName = current.name;
        this.mappedList[this.assign.index].jobRoleId = current.id;
        this.mappedList[this.assign.index].jobRoleDescription =
          current.description;
      }
      this.handler(this.mappedList);
    },
    keyword() {
      this.sorting = null;
     this.updateList(true);
    },
  },
  methods: {
    ...mapActions({
      openModal: "modals/openModal",
      getParticipants: "assessmentSetup/getParticipants",
    }),
    async updateList(reset, fetchAll) {
      if (reset) {
        this.mappedList = [];
        this.pageNr = 1;
      }
      await this.getParticipants({
        id: this.id,
        pageNr: this.pageNr,
        pageSz:  fetchAll ? this.totalCount : this.pageSize,
        keyword: this.keyword,
        sorting: this.sorting,
      });
      let map = this.participants.map((item, idx) => {
        let found = this.jobRoles.find((el) => el.id == item.jobRoleId);
        return {
          ...item,
          index: idx,
          isSelected: false,
          jobRoleDescription: found ? found.description : null,
        };
      });
      this.mappedList = [...this.mappedList, ...map];
      this.updateSelectedItems();
    },
    triggerFetch() {
      if (this.mappedList.length < this.totalCount && this.pageNr < Math.ceil(this.totalCount / this.pageSize)) {
        this.pageNr++;
        this.updateList();
      }
    },
    assignJobRoles(val, jobRole) {
      this.openModal({ modal: "jobRoleModal", props: { jobRole } });
      val ? (this.assign = val) : (this.assign = { all: true, index: null });
    },
    setRotation(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx == index ? -1 * item : 1
      );
    },
    sort(key, index) {
      this.setRotation(index);
      switch (key) {
        case "name":
          this.sorting = {firstName: this.rotationMatrix[0] == -1 ? 1 : 2}
          break;
        case "jobTitle":
          this.sorting = {jobTitle: this.rotationMatrix[1] == -1 ? 1 : 2}
          break;
        case "team":
          this.sorting = {team: this.rotationMatrix[2] == -1 ? 1 : 2}
          break;
        case "jobRole":
          this.sorting = {jobRole: this.rotationMatrix[3] == -1 ? 1 : 2}
          break;
        default:
          break;
      }
      this.updateList(true)
    },
    setKeyword: debounce(function(value) {
      this.keyword = value;
    }, 500),
    updateSelectedItems() {
      this.mappedList.map(item => item.isSelected = this.selectedItems.has(item.id))
      this.isSelectAllActive = this.mappedList.length && this.mappedList
        .reduce((a, b) => this.selectedItems.has(b.id) && a, true);
    },
    toggleItem(id) {
      if(this.selectedItems.has(id)) {
        this.selectedItems.delete(id)
      } else {
        this.selectedItems.add(id);
      }
      this.updateSelectedItems();
    },
    async toggleAll() {
      if(this.mappedList.length < this.totalCount) {
        await this.updateList(true, true);
      }
      if(!this.selectedAllActive) {
        this.mappedList.forEach(x => this.selectedItems.add(x.id))
      } else {
        this.mappedList.forEach(x => this.selectedItems.delete(x.id))
      }
      this.updateSelectedItems();
    },
  },
};
</script>
