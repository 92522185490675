<template>
  <div
    v-if="usersWithNoRole !== 0"
    class="flex flex-grow items-center justify-between bg-blue-light-1 p-2 mb-2"
  >
    <div class="max-w-3/4">
      <p class="text-mdh text-grey-dark-2">
        You have users with unassigned Job Roles. Please review the list of Job
        Roles and assign each participant a Job Role to proceed. You can filter
        by Job Title and use the checkboxes and Assign Job Role button to update
        multiple participants at once. Use the pencil icon next to a participant
        to update Job Roles individually. If you do not see an appropriate Job
        Role, please contact ISM at membersvcs@ismworld.org.
      </p>
    </div>
    <div>
      <p class="text-right font-bold text-l-2 text-grey-dark-1">
        {{ usersWithNoRole
        }}<span class="text-grey-light"> of {{ participantsCount }}</span>
      </p>
      <p class="text-mdh text-grey-dark-2">
        job role(s) unassigned
      </p>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "NoRoleCard",
  computed: {
    ...mapState({
      participantsCount: (state) => state.assessmentSetup.participantsCount,
      usersWithNoRole: (state) => state.assessmentSetup.particpantsWithNoAssignedJobRole,
    }),
  },
};
</script>
